const brandColors = {
  primary: '#2451D9',
  secondary: '#1BA96F',
};

const sidebarColors = {
  backgroundColor: '#F3F6FC',
  color: '#15171A',
  dotfileLogo: '#15171A',
};

const linkColors = {
  color: '#0176FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
