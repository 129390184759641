import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'general_information',
    type: StepTypeEnum.text,
  },
  {
    id: 'before_we_start',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'email_for_corporate_account_creation',
          type: FieldTypeEnum.email,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'legal_entity_type',
          type: FieldTypeEnum.select,
          hasHelper: true,
          isRequired: true,
          isEnabled: true,
          options: ['institution', 'trust'],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'business_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'city',
          type: FieldTypeEnum.text,
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          type: FieldTypeEnum.text,
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'street_address',
          type: FieldTypeEnum.text,
          nested: 'address',
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: 'business_information',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'website_url',
          type: FieldTypeEnum.url,
          isRequired: false,
          isEnabled: false,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'type_of_business',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'administrative_and_support_services',
            'agriculture',
            'arts_entertainment_and_media',
            'construction',
            'cryptocurrency',
            'education',
            'energy_oil_gas',
            'energy_other',
            'financial_services',
            'government',
            'healthcare',
            'hospitality',
            'law_enforcement_and_defense',
            'production_and_manufacturing',
            'professional_services',
            'real_estate',
            'retail_and_wholesale_trade',
            'scientific_services',
            'technology_it',
            'transportation',
            'utilities',
            'trust',
          ],
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'business_description',
          type: FieldTypeEnum.textarea,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'nexo_account_usage_description',
          type: FieldTypeEnum.textarea,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: 'financial_information',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'source_of_funds',
          type: FieldTypeEnum.select,
          isRequired: false,
          isEnabled: false,
          options: [
            'income',
            'investments',
            'loan',
            'sale_of_assets',
            'clients_funds',
            'early_adopter',
            'other',
            'dividend_or_income_from_business',
            'clients_investors_funds',
            'profits_from_investments',
            'initial_investment_by_companys_owners',
            'business_gain_from_a_company_sale_property_sale',
            'sale_of_assets_investing_trading',
            'commercial_loan',
            'i_have_not_topped_up_or_intend_to_top_up_any_fiat_funds_with_nexo',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'source_of_crypto',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'na',
            'crypto_assets_purchased_on_crypto_exchangesbrokers',
            'trading_profits_from_exchangersbrokers',
            'income_from_services',
            'initial_coin_offering_ico_and_token_sales',
            'mining',
            'staking_master_nodes',
            'airdrops',
            'coin_or_token_swaps',
            'lending_and_liquidity_pools',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'annual_profit',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'from_0_to_50k_usd',
            'from_50k_to_250k_usd',
            'from_250k_to_1m_usd',
            'from_1m_to_5m_usd',
            'from_5m_to_10m_usd',
            'over_10m_usd',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'annual_turnover',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'from_0_to_50k_usd',
            'from_50k_to_250k_usd',
            'from_250k_to_1m_usd',
            'from_1m_to_5m_usd',
            'from_5m_to_10m_usd',
            'over_10m_usd',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'expected_investment',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'from_0_to_5k_usd',
            'from_5k_to_10k_usd',
            'from_10k_to_25k_usd',
            'from_25k_to_50k_usd',
            'from_50k_to_250k_usd',
            'from_250k_to_1m_usd',
            'from_1m_to_5m_usd',
            'from_5m_usd_to_10m_usd',
            'over_10m_usd',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'high_risk_countries_of_operation',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'burkina_faso',
            'myanmar',
            'nicaragua',
            'cameroon',
            'gibraltar',
            'liberia',
            'mozambique',
            'niger',
            'nigeria',
            'tanzania',
            'turkey',
            'united_arab_emirates',
            'vietnam',
            'none_of_the_above',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'high_risk_activities',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: [
            'cryptocurrency_exchange_broker',
            'tokensale_platform',
            'initial_coin_offering_security_coin_offering',
            'brokerage_noncrypto',
            'crypto_borrowing_lending',
            'fx_cfd_provider',
            'foreign_exchange_broker',
            'trading_platform',
            'bank',
            'cryptocurrency_advisor',
            'financial_advisor_noncrypto',
            'noncustodial_crypto_wallet',
            'crypto_custody',
            'investment_fund_hedge_fund',
            'venture_capital',
            'segregated_portfolio_company',
            'none_of_the_above',
          ],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
        {
          id: 'regulated_with_a_financial_regulator',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: ['regulated', 'exempted', 'nonregulated', 'na'],
          entityType: FieldEntityTypeEnum.case,
          propertyType: FieldPropertyTypeEnum.custom,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
  {
    id: 'aggrement_to_share_information',
    type: StepTypeEnum.terms_and_conditions,
    config: {
      pdfUrl: '',
    },
  },
];
